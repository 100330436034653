import React from "react";
import { Link } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import Container from "../components/container";
import PageHeader from "../components/page-header";

import * as styles from "../styles/components/404.module.css";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <PageHeader title="404" />
    <div className={styles.root}>
      <Container>
        <div className={styles.containerContent404}>
          <p className="normalpp">Sorry, we can’t find that page. Please try again, or <Link to="/">visit the homepage</Link>.</p>
        </div>
      </Container>
    </div>
  </Layout>
);

export default NotFoundPage;
